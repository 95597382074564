<template>
  <article class="md:h-full mb-20">
    <section class="border border-blue-200 duration-300 md:flex md:h-full">
      <div class="md:w-4/12 xl:w-3/10 overflow-y-auto">
        <div class="font-medium px-8 py-5 text-gray-700">
          All Companies
        </div>
        <datatable
          :url="companies.url"
          :ajax="true"
          :ajaxPagination="true"
          :columns="companies.columns"
          :filters="companies.filters"
          :query="companies.query"
          :className="tableRowClassName"
          :fillable="true"
          :dropdown="false"
          :on-click="selectCompany"
          ref="companies"
        >
          <template #header>
            <div class="pt-4">
              <search-form
                placeholder="Search companies"
                class="mb-3"
                v-model="companies.query"
                @submit="reloadTable('companies', true)"
              />
            </div>
            <button
              type="button"
              class="btn btn-sm btn-gray mb-3"
              @click="toggleLetterFilters"
            >
              {{ showLetterFilters ? 'Hide' : 'Show' }} Filters
            </button>
            <template v-if="showLetterFilters">
              <div class="flex -mx-1 flex-wrap">
                <template v-for="(filter, index) in numberOptions">
                  <button
                    :key="index"
                    type="button"
                    class="border border-blue-200 hover:border-blue-500 font-medium mb-2 mx-1 py-1 px-3 rounded-sm text-sm uppercase"
                    :class="{
                      'bg-blue-500 border-blue-500 text-white': currentFilter === filter,
                      'text-gray-600 hover:text-blue-500': currentFilter !== filter
                    }"
                    @click.prevent="toggleFilter(filter)"
                  >
                    {{ filter.name }}
                  </button>
                </template>
              </div>
              <div class="border-t border-blue-200 flex flex-wrap mt-2 -mx-1 pt-4">
                <template v-for="(filter, index) in alphabetOptions">
                  <button
                    :key="index"
                    type="button"
                    class="border border-blue-200 hover:border-blue-500 font-medium mb-2 mx-1 py-1 px-3 rounded-sm text-sm uppercase"
                    :class="{
                      'bg-blue-500 border-blue-500 text-white': currentFilter === filter,
                      'text-gray-600 hover:text-blue-500': currentFilter !== filter
                    }"
                    @click.prevent="toggleFilter(filter)"
                  >
                    {{ filter.name }}
                  </button>
                </template>
              </div>
            </template>
          </template>
        </datatable>
      </div>
      <div class="border-l border-blue-200 flex-grow overflow-y-auto">
        <template v-if="companies.selected">
          <div class="font-medium px-8 py-5 text-gray-700">
            {{ companies.selected && companies.selected.name | sentenceCase }}
          </div>
          <datatable
            :url="users.url"
            :ajax="true"
            :ajaxPagination="true"
            :columns="users.columns"
            :filters="users.filters"
            :query="users.query"
            :fillable="true"
            :dropdown="false"
            :on-click="click"
            sort="salary"
            order="desc"
            ref="users"
          >
            <template #header>
              <div class="pt-4">
                <search-form
                  placeholder="Search users names, emails, phone numbers, bvn, etc."
                  class="mb-5"
                  v-model="users.query"
                  @submit="reloadTable('users')"
                />
              </div>
            </template>
            <template v-slot:td-3="{ item: { row } }">
              <span class="badge badge-green" v-if="isActive(row)">Active</span>
              <span class="badge badge-orange" v-else>Inactive</span>
            </template>
          </datatable>
        </template>
        <template v-else>
          <div class="flex h-full items-center justify-center p-10 text-center">
            <h4 class="text-lg font-medium text-gray-700">
              <span class="mr-2">Click on a company</span>
              <span class="md:hidden mr-2">above</span>
              <span class="hidden md:inline mr-2">on the left</span>
              <span>to view their users</span>
            </h4>
          </div>
        </template>
      </div>
    </section>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />
  </article>
</template>

<script>
  export default {
    data () {
      return {
        currentFilter: null,
        alphabets: 'abcdefghijklmnopqrstuvwxyz',
        numbers: '0123456789',
        showLetterFilters: false,
        companies: this.$options.resource([], {
          selected: null,
          url: `${this.$baseurl}/admin/companies/unique`,
          query: '',
          columns: [
            {
              name: 'employer',
              th: 'Company Name'
            }
          ],
          filters: [],
        }),
        users: this.$options.resource([], {
          selected: null,
          url: '',
          query: '',
          columns: [
            {
              name: 'id',
              th: 'User ID',
            },
            {
              name: 'name',
              th: 'Name',
              render: (user) => user.name + ' ' + user.last_name
            },
            {
              name: 'account_status',
              th: 'Status',
              // render: (user) => {
              //   if (user.personal_account?.personal_transactions?.length || user.loan?.length) {
              //     return '<span class="badge badge-green">Active</span>';
              //   }

              //   return '<span class="badge badge-orange">Inactive</span>';
              // }
            },
            {
              name: 'job_title',
              th: 'Job Title',
              render: (user) => user?.profile?.position || 'Job title not specified'
            },
            {
              name: 'salary',
              th: 'Salary',
              render: (user) => user?.profile?.salary ? `₦${this.$options.filters.currency(user?.profile?.salary)}` : 'Not Provided'
            },
            {
              name: 'phone_no',
              th: 'Phone Number'
            },
            {
              name: 'email',
              th: 'Email Address',
            },
            {
              name: 'official_email',
              th: 'Workplace Email',
            },
          ],
          filters: [],
        })
      }
    },
    computed: {
      alphabetOptions() {
        return this.alphabets?.split('').map(letter => ({ name: letter }));
      },
      numberOptions() {
        return this.numbers?.split('').map(letter => ({ name: letter }));
      },
      isOpen() {
        return this.users.selected && false;
      }
    },
    watch: {
      'companies.selected'(company) {
        this.users.url = `${this.$baseurl}/admin/companies/users/by-unique-company-name?companyName=${encodeURIComponent(company.employer)}`
        this.$nextTick(() => {
          this.reloadTable('users', true);
        })
      }
    },
    methods: {
      isActive(user) {
        return user.personal_account?.personal_transactions?.length || user.loan?.length;
      },
      selectCompany(company) {
        this.companies.selected = company;
        this.$refs.companies.renderData();
      },
      click(user) {
        this.users.selected = user;
        this.$refs.userModal.open();
      },
      reloadTable(tableName, reset = false) {
        this.$refs[tableName]?.loadAjaxData(reset);
      },
      tableRowClassName(company, index, row) {
        return row.selected || company === this.companies.selected ? 'selected' : ''
      },
      toggleFilter(filter) {
        if (this.currentFilter === filter) {
          this.currentFilter = null;
        }else {
          this.currentFilter = filter;
        }

        this.$refs.companies.clickedFilter(this.currentFilter);
      },
      toggleLetterFilters() {
        this.showLetterFilters = !this.showLetterFilters;
      },
      userDeleted() {
        this.reloadTable();
      },
      userModalClosed() {
        this.users.selected = null;
      },
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.error = null;
        this.form.success = null;
        this.form.loading = true;
        await this.sendRequest('admin.assignments.assign', {
          data: this.getFormData(),
          success: () => {
            this.form.success = true;
            this.$refs.companies.clearSelection();
            this.reloadTable();

            setTimeout(() => {
              this.form.success = false;
            }, 2000);
          },
          error: error => {
            this.form.error = error;
          }
        });
        this.form.loading = false;
      }
    }
  }
</script>